<template>
  <el-main>
    <el-button style="margin-bottom: 10px" type="primary" size="small" @click="() => (dialogVisible = true)">添加分享员
    </el-button>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="分享员昵称：">
        <el-input v-model="searchForm.keyword" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getShareList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch()">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column header-align="center" align="center" label="ID" prop="id"></el-table-column>
      <el-table-column header-align="center" align="center" label="昵称" prop="nickname"></el-table-column>
      <el-table-column header-align="center" align="center" label="openid" prop="openid"></el-table-column>
      <el-table-column header-align="center" align="center" label="绑定时间">
        <template v-slot="{ row }">
          {{ getDateformat(row.bind_time) }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="绑定状态">
        <template v-slot="{ row }">
          <div :style="{ color: !row.bind_status ? '#409EFF' : row.bind_status === 1 ? 'green' : 'red' }">{{
          !row.bind_status ? '已邀请' : row.bind_status === 1 ? '绑定中' : '已解绑' }}</div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="操作" prop="id">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="operation(row)">{{ !row.bind_status ? '等待用户确认' : row.bind_status
          === 1 ? '解绑' : '绑定' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updatePageNum"></Paging>
    <el-dialog title="选择用户" :visible.sync="dialogVisible" width="52%">
      <ListComponents v-if="dialogVisible" :styleMethon="5" @getListItem="getListItem"></ListComponents>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ListComponents from '@/components/listComponents';
export default {
  components: {
    Paging,
    ListComponents,
  },
  data () {
    return {
      dialogVisible: false,
      getDateformat,
      searchForm: {
        keyword: '',
      },
      page: 1,
      rows: 10,
      total: 0,
      tableData: [],
    };
  },
  created () {
    this.getShareList();
  },
  methods: {
    cancelSearch () {
      this.page = 1;
      this.searchForm = {
        keyword: '',
      };
      this.getShareList(1);
    },
    updatePageNum (val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      this.getShareList();
    },
    getShareList (style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchForm.keyword) {
        obj.keyword = this.searchForm.keyword;
      }
      this.$axios.post(this.$api.shopDiy.getSharerLists, obj).then(res => {
        if (res.code === 0) {
          this.tableData = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getListItem (data) {
      this.bindUser(data.mini_openid);
    },
    bindUser (openid) {
      this.$axios.post(this.$api.shopDiy.bindSharer, { openid: [openid] }).then(res => {
        if (res.code == 0) {
          this.$message.success('已邀请，请等待用户确认');
          this.getShareList(1);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    unbindUser (openid) {
      this.$axios.post(this.$api.shopDiy.unbindSharer, { openid: [openid] }).then(res => {
        if (res.code == 0) {
          this.$message.success('已成功解绑');
          this.getShareList(1);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    operation (row) {
      if (!row.bind_status) {
        this.$message.warning('请等待用户确认');
      } else if (row.bind_status === 1) {
        //解绑用户
        this.$confirm('此操作将解绑该分享员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.unbindUser(row.openid);
          })
          .catch(() => { });
      } else if (row.bind_status === 2) {
        // 重新绑定
        this.bindUser(row.openid);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
